import Image1 from '../../assets/project1.jpg'
import Image2 from '../../assets/bnbclone.png'
import Image3 from '../../assets/project3.jpg'
import Image4 from '../../assets/project4.jpg'
import Image5 from '../../assets/project5.jpg'
import Image6 from '../../assets/project6.jpg'


const data = [
    {
        id: 1,
        category: 'backend',
        image: Image2,
        title: "Django REST API",
        desc: "Django REST API is known for its role in the development of robust websites. The backend server utilizes JWT tokens to handle login to ensure data security.",
        demo: 'https://demo.devprattle.com/',
        github: 'https://github.com/Kamire-J/django-nextjs-app'
    },
    {
        id: 2,
        category: 'frontend',
        image: Image2,
        title: "AirBnb Clone",
        desc: "NextJS(Typescript) airbnb clone project. The project covers data handling and user experience effectively without compromising data security.",
        demo: 'https://demo.devprattle.com/',
        github: 'https://github.com/Kamire-J/django-nextjs-app'
    },
    // {
    //     id: 3,
    //     category: 'frontend',
    //     image: Image3,
    //     title: "Project Title Three (Frontend)",
    //     desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    //     demo: '#',
    //     github: '#'
    // },
    // {
    //     id: 4,
    //     category: 'backend',
    //     image: Image4,
    //     title: "Project Title Four (UIUX)",
    //     desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    //     demo: '',
    //     github: '#'
    // },
    // {
    //     id: 5,
    //     category: 'backend',
    //     image: Image5,
    //     title: "Project Title Five (UIUX)",
    //     desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! ",
    //     demo: '#',
    //     github: '#'
    // },
    // {
    //     id: 6,
    //     category: 'frontend',
    //     image: Image6,
    //     title: "Project Title Six (Frontend)",
    //     desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    //     demo: '#',
    //     github: '#'
    // },
    
]


export default data